import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import md5 from "js-md5";

const { crypto } = window;

async function generateKeyAndIV(keyString, ivString) {

  const keyHash = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(keyString));


  const ivHash = md5.arrayBuffer(ivString);

  const key = new Uint8Array(keyHash);
  const iv = new Uint8Array(ivHash);

  return { key, iv };
}

async function decrypt(encryptedHex, keyString, ivString) {

  const { key, iv } = await generateKeyAndIV(keyString, ivString);


  const encryptedData = new Uint8Array(
    encryptedHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );


  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    key,
    { name: "AES-CBC" },
    false,
    ["decrypt"]
  );

  // Dekripsi data
  const decryptedData = await crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv,
    },
    cryptoKey,
    encryptedData
  );

  // Decode hasil dekripsi menjadi string
  return new TextDecoder().decode(decryptedData);
}

export function useGetFormBuilder(id) {
  const api = useQuery({
    queryKey: ["form", id],
    queryFn: async () => {
      const res = await axios.get(`${process.env.REACT_APP_SERVER2}/api/form-builder/${id}`)
      return JSON.parse(res?.data?.data)
    },
    enabled: !!id
  })
  return api
}

export async function ocr(codegambarbase64) {
  let hasil = "";
  // modalloading()
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/digitech/ocr-ktp`, {
      image: codegambarbase64,
    })
    .then((res) => {
      const hasilaxios = res.data.data;

      const ocrktp = {
        noidentitas: hasilaxios.nik,
        namasesuaiidentitas: hasilaxios.nama,
        tempatlahir: hasilaxios.ttl ? hasilaxios.ttl.split(",")[0] : null,
        tanggallahir: hasilaxios.ttl ? hasilaxios.ttl.split(",")[1] : null,
        jeniskelamin:
          hasilaxios.jeniskelamin === "LAKI-LAKI"
            ? "Laki - Laki"
            : hasilaxios.jeniskelamin,
        golongan_darah: hasilaxios.golongan_darah,
        alamatsesuaiidentitas: hasilaxios.alamat,
        rt: hasilaxios.rtrw ? hasilaxios.rtrw.split("/")[0] : null,
        rw: hasilaxios.rtrw ? hasilaxios.rtrw.split("/")[1] : null,
        kelurahandesa: hasilaxios.desa_kelurahan,
        kabupatenkota: hasilaxios.kota_kabupaten,
        kecamatan: hasilaxios.kecamatan,
        provinsi: hasilaxios.provinsi,
        agama: hasilaxios.agama
          ? `${hasilaxios.agama.valueOf().substring(0, 1)}${hasilaxios.agama
            .valueOf()
            .substring(1, hasilaxios.agama.valueOf().length)
            .toLowerCase()}`
          : null,
        statusmenikah:
          hasilaxios.status_perkawinan === "KAWIN"
            ? "Menikah"
            : "Belum Menikah",
        pekerjaan: hasilaxios.pekerjaan,
        kewarganegaraan: hasilaxios.kewarganegaraan,
        namaibukandung: "",
        // berlakuhingga: "SEUMUR HIDUP",
      };

      hasil = ocrktp;
    })
    .catch((error) => {
      hasil = error.response.status;

      // 
      // 
      // throw error;
      //
    });
  return hasil;
}

export async function ocrnpwp(codegambarbase64) {
  let hasil = "";
  // modalloading()
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/digitech/ocr-npwp`, {
      image: codegambarbase64,
    })
    .then((res) => {
      hasil = res.data.data.npwpId; //string
    })
    .catch(function (error) {
      hasil = { error: error.response.status }; //code 200 => number
      // hasil = error.response //code 200 => number
      // alert(error.response.data.data[0].message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function validasiDukcapil(payload) {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/validasi/dukcapil-v2`,
      payload
    )
    .then((res) => {
      return { status: true, data: res.data };
    })
    .catch((err) => {
      return { status: false, data: err };
    });
}

export async function validasiDTTOT(payload) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/validasi/dttot-v2`,
      payload
    );
    return { status: true, data: response.data };
  } catch (err) {
    return { status: false, data: err };
  }
}

export function cropImage(picture) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = picture;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 350;
      canvas.height = 350;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(
        image,
        image.width / 2 - (image.width * 5) / 100,
        image.height / 2 - (image.height * 40) / 100,
        image.width,
        image.height,
        0,
        0,
        image.width,
        image.height
      );
      const dataURL = canvas.toDataURL();

      resolve(dataURL);
    };
  });
}

const resizeImage = (file) => {
  let resizedFile = null;
  const targetCanvas = document.createElement("canvas");
  const ctx = targetCanvas.getContext("2d");
  const img = new Image();
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    targetCanvas.width = 500;
    targetCanvas.height = 500 * (img.height / img.width);
    ctx.drawImage(img, 0, 0, targetCanvas.width, targetCanvas.height);
    targetCanvas.toBlob(function (resizedBlob) {
      resizedFile = new File([resizedBlob], "resizedImage", {
        lastModified: new Date().getTime(),
        type: "image/jpeg",
      });
    });
    // )
  };
  return resizedFile;
};

const resizeBase64Image = (base64, width, height) => {
  // Create a canvas element

  const canvas = document.createElement("canvas");

  // Create an image element from the base64 string
  const image = new Image();
  image.src = base64;

  // Return a Promise that resolves when the image has loaded
  return new Promise((resolve, reject) => {
    image.onload = () => {
      // Calculate the aspect ratio of the image
      const aspectRatio = image.width / image.height;

      // Calculate the best fit dimensions for the canvas
      if (width / height > aspectRatio) {
        canvas.width = height * aspectRatio;
        canvas.height = height;
      } else {
        canvas.width = width;
        canvas.height = width / aspectRatio;
      }

      // Draw the image to the canvas
      canvas
        .getContext("2d")
        .drawImage(image, 0, 0, canvas.width, canvas.height);

      // Resolve the Promise with the resized image as a base64 string
      resolve(canvas.toDataURL());
    };

    image.onerror = reject;
  });
};

export async function cropCustFace(base64Face) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = base64Face;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 350;
      canvas.height = 350;
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        image.width,
        image.height
      );

      const dataURL = canvas.toDataURL();

      resolve(dataURL);
    };
  });
}

export async function validasiIbuKandung(payload) {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/validasi/ibu-kandung`,
      payload
    )
    .then((res) => {
      return { status: true, data: res.data };
    })
    .catch((err) => {
      return { status: false, data: err };
    });
}

export async function convertImageToFile(image, name) {
  const decodedData = atob(
    image
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
  );
  const buffer = new ArrayBuffer(decodedData.length);
  const array = new Uint8Array(buffer);

  for (let i = 0; i < decodedData.length; i++) {
    array[i] = decodedData.charCodeAt(i);
  }
  const blob = new Blob([buffer], { type: "image/jpeg" });
  const file = new File([blob], name, {
    lastModified: new Date().getTime(),
    type: "image/jpeg",
  });
  const resize = await resizeImage(file);
  return file;
}

export async function cekswafotoktp(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER2}/form-data-swafoto/check`,
      data
    );

    return { status: true, data: response };
  } catch (err) {
    return { status: false, data: err };
  }
}

export async function validasiSwafoto(image) {
  try {

    const base64 = await cropImage(image);
    // const file = await convertImageToFile(base64, "crop.jpeg");
    const result = await ocr(base64.split(",")[1])
      .then((res) => {

        if (res === 400) {
          return { status: false };
        }
        return { status: true, data: res };
      })
      .catch((err) => {

        return { status: false };
      });

    return result;
  } catch {
    return {};
  }
}

export async function apiuploaddokumen(pic, jenisgambar, namaform, idDips) {
  let hasil = "";
  const formData = new FormData();
  let file = await convertImageToFile(pic, `blob${jenisgambar}.png`);
  formData.append("idDips", idDips); //ttd
  formData.append(jenisgambar, file);

  await axios
    .post(`${process.env.REACT_APP_SERVER2}/form-data/${namaform}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      hasil = res;

    })
    .catch(function (error) {
      hasil = { error: error.response.status };
      // hasil = error.response //code 200 => number
      alert(error.response.data.message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx


        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function formporto(payload) {
  let hasil = "";
  await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/customer-portfolio/portfolio-data`,
      payload
    )
    .then((res) => {
      hasil = res;
    })
    .catch(function (error) {
      hasil = "error";

      // hasil = error.response //code 200 => number
      // alert(error.response.message);
      // alert(error.response.data[0].message.replace("datadiri.", ""));
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx


        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function formsave(payload) {
  let hasil = "";
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/form/save`, payload)
    .then((res) => {
      hasil = res.data.data.idForm;
    })
    .catch(function (error) {
      hasil = "error"; //code 200 => number
      // alert(error.response.data.data[0].message)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // 

        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function apiswafotocompare(payload) {
  let hasil = "";
  await axios
    .post(
      `${process.env.REACT_APP_SERVER2}/api/digitech/swafoto-compare`,
      payload
    )
    .then((res) => {
      hasil = res;
      // pindahformupload()
    })
    .catch(function (error) {
      alert(
        `Error Komparasi Swafoto:\n${error.response.data.message}\n${error.response.data.name}\n\nCoba ulangi kembali!`
      );
      hasil = "error"; //code 200 => number
      alert(error.response.data.message);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx


        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function getlistfromurl(
  url,
  namakolom,
  idprovinsi,
  idkabupaten,
  idkecamatan,
  jenispengaduan
) {
  let hasil = "";
  // let bahanreplace = ':id_' + namakolom.valueOf().toLowerCase().split("(")[0].split("/")[0].split(" ")[0];
  await axios
    .get(
      url
        .replace(":id_provinsi", idprovinsi)
        .replace(":id_kabupaten", idkabupaten)
        .replace(":id_kecamatan", idkecamatan)
        .replace(":jenisPengaduan", jenispengaduan)
    )
    .then((res) => {
      hasil = res.data.data;
    })
    .catch(function (error) {
      hasil = "error"; //code 200 => number
      if (error.response) {
        // alert(`${useTranslation('error')}:${useTranslation('tidakdapatmengambildatauntukkolom')} '${namakolom}'`)

        // 

        //code 200 (number)
      } else if (error.request) {
        // The request was made but no response was received

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function getkodepos({ kel, kec, kab, prov }) {
  let hasil = "";
  // let bahanreplace = ':id_' + namakolom.valueOf().toLowerCase().split("(")[0].split("/")[0].split(" ")[0];
  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/kodepos/get-by-wilayah`, {
      kelurahan: kel,
      kecamatan: kec,
      kabupaten: kab?.toString().toLowerCase().replace("kabupaten ", ""),
      provinsi: prov,
    })
    .then((res) => {
      hasil = res.data.data;
    })
    .catch(function (error) {
      hasil = error.response?.data?.data; //code 200 => number
      if (error.response) {


        //code 200 (number)
      } else if (error.request) {
        // The request was made but no response was received

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function kirimformtransaksi(url, payload, config) {
  let hasil = "";
  await axios
    .post(`${process.env.REACT_APP_SERVER2}${url}`, payload, config)
    .then((res) => {
      hasil = res.data;
    })
    .catch(function (error) {
      hasil = error; //code 200 => number
      if (url !== "/api/rating-agent/") {

        if (typeof error.response.data === "object") {
          if (url === "/api/customer-portfolio/validasi/wajah-nasabah") {
            // if (error.response.data.data.respCode === "11") {
            //   <Modalbuatan show={true} judul="Error" isi={error.response.data.message} />
            // } else {
            // }
          } else {
            // if (url !== "/api/customer-portfolio/validasi/data-nasabah")
            // alert(error.response.data.message);
          }
        }
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx


        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function getstatusapproval(idform) {
  let hasil = "";
  await axios
    .get(`${process.env.REACT_APP_SERVER2}/api/approval/status/${idform}`)
    .then((res) => {
      hasil = res.data; //.code
    })
    .catch(function (error) {
      // hasil = "error" //code 200 => number
      // alert(error.response.data.data[0].message)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // 
        hasil = error.response.status;

        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function ambilnomorform() {
  let hasil = { sukses: null, gagal: null };
  await axios
    .get(`${process.env.REACT_APP_SERVER2}/api/transaction/generate-noForm`)
    .then((res) => {
      hasil.sukses = res.data.data.noForm;
    })
    .catch(function (error) {
      // hasil = "error" //code 200 => number
      // alert(error.response.data.data[0].message)
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // 
        hasil.gagal = error.response.status;

        //code 200 (number)

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

      } else {
        // Something happened in setting up the request that triggered an Error

      }
      //
    });
  return hasil;
}

export async function GetDataKtp(authData, language) {
  let lowercasedData = {};

  await axios
    .get(
      `${process.env.REACT_APP_SERVER2}/api/ktp/get-by-idDips/${authData?.idDips
      }?bahasa=${language === "Idn" || language === undefined ? "id" : "en"}`
    )
    .then((res) => {

      let itemsDataKtp = res.data.data;
      for (let key in itemsDataKtp) {
        lowercasedData[key.toLowerCase()] = itemsDataKtp[key];
      }
    })
    .catch((err) => {
      lowercasedData = "error";

    });

  return lowercasedData;
}

export async function getDataCore(idDips, noCif) {
  let ktp = null;

  await axios
    .post(`${process.env.REACT_APP_SERVER2}/api/customer/get-data-core`, {
      idDips,
      noCif,
    })
    .then(async (res) => {
      const copyData = { ...res.data.data };
      const tglLahir = await decrypt(copyData.tglLahir, idDips, copyData.reffNo);
      const namaIbu = await decrypt(copyData.namaIbu, idDips, copyData.reffNo);
      const tempatLahir = await decrypt(copyData.tempatLahir, idDips, copyData.reffNo);
      const npwp = await decrypt(copyData.npwp, idDips, copyData.reffNo);
      const namaCust = await decrypt(copyData.namaCust, idDips, copyData.reffNo);
      const nomorId = await decrypt(copyData.nomorId, idDips, copyData.reffNo);

      ktp = { ...copyData, tglLahir, namaIbu, tempatLahir, npwp, namaCust, nomorId };

    })
    .catch((err) => {
      console.log(err, "err ktp");
      ktp = "error";

    });
  console.log(ktp, 'ktp')
  return ktp;
}
