import { useContext } from "react";
import { client } from "../utils/rabbitMQ2";
import { ZoomClientContext } from "../ZoomClientContext";

//mirroring

const mirroringsendkey = (konten, csId) => {
  const destination = `/exchange/dips361-cs-send-key/dips.direct.cs.${csId}.send.key`;
  const transaction = konten; //data json
  // 
  client.publish({
    destination: destination,
    body: JSON.stringify({
      from: "Cust",
      to: "CS",
      created: Date.now().toString(),
      transaction,
    }),
  });
};

const mirroringsendendpoint = (endpoint, csId) => {
  const destination = `/exchange/dips361-cs-send-endpoint/dips.direct.cs.${csId}.send.endpoint`;
  const transaction = {
    // iddips,
    endpoint, //code
  };
  client.publish({
    destination: destination,
    body: JSON.stringify({
      from: "Cust",
      to: "CS",
      created: Date.now().toString(),
      transaction,
    }),
  });
};

const mirror = (konten, endpoint, csid) => {
  
  if (client.connected) {
    try {
      if (endpoint === 999) {
        // 
      } else {
        mirroringsendendpoint(endpoint, csid);
        // 
      }

      // 
      if (Object.values(konten).length < 1) {
        // 
      } else {
        mirroringsendkey(konten, csid);
        // 
      }
    } catch (er) {
      
      // alert(er)
    }
  } else {
    
    client.activate();
    client.onConnect = () => {
      mirror(konten, endpoint, csid);
    };
  }
};

// const mirror = {
//     sendkey: mirroringsendkey(konten, csid),
//     sendendpoint: mirroringsendendpoint(endpoint, csid)
// };

export default mirror;
