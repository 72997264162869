import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export function TranslateInstance(language) {
  i18n.use(initReactI18next).init({
    fallbackLng: "Idn",
    lng: language,
    resources: {
    Eng: {
      translations: require('./locales/en/translations.json')
    },
    Idn: {
      translations: require('./locales/id/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['Idn', 'Eng'];
}