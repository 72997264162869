import React, { useContext, useEffect, useState } from 'react'
import { AuthData } from './AuthData'
import { Navigate } from 'react-router-dom'

function ProtectedRoutes({ Component }) {
    const { authData } = useContext(AuthData)

    return Object.keys(authData).length === 0 ? <Navigate to="/" /> : <Component />
}

export default ProtectedRoutes