import React, { useContext, useEffect, useState } from "react";
import {
  AutoComplete,
  Currency,
  Datalist,
  Datepicker,
  Dropdown,
  DropdownSumberDana,
  RadioButton,
  Checkbox,
  TextArea,
  TextInfo,
  InputFile,
  InputGambar,
} from "./ElementsPhase2";
import { Row, Col } from "react-bootstrap";
import { LanguageContext } from "../../LanguageContext";
import { AuthData } from "../../AuthData";

export default function FormElementsPhase2({
  data,
  formState,
  handleFormChange,
  handleFormBlur,
  index,
  altLabel,
  stateArray,
  state,
  setIsValid,
  errorState,
}) {
  let altLabelInside = altLabel;
  function formatString(input) {
    if (input)
      return input
        .toLowerCase()
        .replace(/ /g, "")
        .replace(/\./g, "")
        .replace(/\//g, "")
        .replace(/-/g, "");
  }
  const { language } = useContext(LanguageContext);
  const { authData } = useContext(AuthData);
  const bahasa = language;
  let formToMap = data;

  function getLabelFromKey(form) {
    let defaultObj = null;
    form.forEach((val, i) => {
      if (!["Header Text", "Label", "Paragraph"]?.includes(val?.name)) {
        val.components.forEach((component) => {
          if (component.label.labelIdn) {
            defaultObj = {
              ...defaultObj,
              [component.label.labelIdn.toLowerCase().replace(/[ ./-]/g, "")]:
                null,
            };
          }
        });
      }
    });
    return defaultObj;
  }

  function mergeObjects(obj1, obj2) {
    const mergedObj = {
      from: obj1.from,
      noForm: obj1.noForm,
      retrievalReferenceNumber: obj1.retrievalReferenceNumber,
      systemTraceAuditNumber: obj1.systemTraceAuditNumber,
    };

    for (const key in obj1) {
      if (obj2.hasOwnProperty(key)) {
        mergedObj[key] = obj1[key]; // Keep values from obj1 for existing keys
      }
    }

    for (const key in obj2) {
      if (!mergedObj.hasOwnProperty(key)) {
        mergedObj[key] = null;
      }
    }

    return mergedObj;
  }

  // useEffect(() => {
  //   if (
  //     formState?.jenislayanan?.labelIdn === "BIFAST" &&
  //     (formState?.tipelayanan?.labelIdn === "Proxy" ||
  //       formState?.tipelayanan === "Proxy")
  //   ) {
  //     setFormToMap(formProxy);
  //   } else if (formState?.jenislayanan?.labelIdn === "BIFAST") {
  //     setFormToMap(formBIFAST);
  //   } else {
  //     setFormToMap(data);
  //   }
  // }, [formState?.jenislayanan, formState?.tipelayanan]);

  // useEffect(() => {
  //   let objToSet;
  //   let currentFormField;
  //   if (
  //     formState?.jenislayanan?.labelIdn === "BIFAST" &&
  //     (formState?.tipelayanan?.labelIdn === "Proxy" ||
  //       formState?.tipelayanan === "Proxy")
  //   ) {
  //     objToSet = getLabelFromKey(formProxy);
  //     currentFormField = formProxy;
  //   } else if (formState?.jenislayanan?.labelIdn === "BIFAST") {
  //     objToSet = getLabelFromKey(formBIFAST);
  //     currentFormField = formBIFAST;
  //   } else {
  //     objToSet = getLabelFromKey(data);
  //     currentFormField = data;
  //   }
  //   const isTheSame =
  //     JSON.stringify(Object.keys(objToSet)) ===
  //     JSON.stringify(Object.keys(formState));
  //   const isSameFormFields =
  //     JSON.stringify(formFields) === JSON.stringify(currentFormField);
  //   if (!isSameFormFields) {
  //     setFormFields(currentFormField);
  //   }
  //   if (objToSet && !isTheSame) {
  //     const newObj = mergeObjects(formState, objToSet);
  //     setFormState((prev) => {
  //       const copy = [...prev];
  //       copy[index] = newObj;
  //       return copy;
  //     });
  //   }
  // }, [formState?.jenislayanan, formState?.tipelayanan, formToMap, formFields]);

  return (
    formToMap &&
    formToMap.map((value, i) => {
      const md = value && value.components.filter((el) => !el.props.hidden);
      return (
        <Row key={i} md={md.length} sm={1} xs={1}>
          {value.components.map((component, idx) => {
            if (component.label.labelIdn)
              altLabelInside = formatString(component.label.labelIdn);
            const lowerCasedLabel = {
              ...component,
              bahasa: bahasa,
              label: {
                ...component.label,
                labelIdnLowerCase: formatString(component.label.labelIdn),
                labelEngLowerCase: formatString(component.label.labelEng),
              },
            };
            return component.name === "AutoComplete" ? (
              <AutoComplete
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "Currency" ? (
              <Currency
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
                form={formToMap}
                stateArray={stateArray}
                state={state}
                setIsValid={setIsValid}
              />
            ) : component.name === "Dropdown" ? (
              <Dropdown
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "DropdownSumberDana" ? (
              <DropdownSumberDana
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
                form={formToMap}
                stateArray={stateArray}
              />
            ) : component.name === "Datalist" ? (
              <Datalist
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "Datepicker" ? (
              <Datepicker
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "TextArea" ? (
              <TextArea
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "RadioButton" ? (
              <RadioButton
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "Checkbox" ? (
              <Checkbox
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "TextInfo" ? (
              <TextInfo
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "InputFile" ? (
              <InputFile
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : component.name === "InputGambar" ? (
              <InputGambar
                data={lowerCasedLabel}
                key={idx}
                altLabel={altLabelInside}
                formState={formState}
                handleFormChange={handleFormChange}
                handleFormBlur={handleFormBlur}
                index={index}
                errorState={errorState}
              />
            ) : (
              <Col key={idx}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: component.label[`label${bahasa}`],
                  }}
                ></div>
              </Col>
            );
          })}
        </Row>
      );
    })
  );
}
