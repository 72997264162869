export default function UploadIcon({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 45 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4051 18.3367V0.5625H3.17092C1.62584 0.5625 0.382812 1.96091 0.382812 3.69912V64.3404C0.382812 66.0786 1.62584 67.477 3.17092 67.477H42.2044C43.7495 67.477 44.9925 66.0786 44.9925 64.3404V21.4733H29.1932C27.6598 21.4733 26.4051 20.0618 26.4051 18.3367ZM33.9771 46.5675H26.4051V57.0229C26.4051 58.1782 25.5733 59.114 24.5464 59.114H20.8289C19.802 59.114 18.9702 58.1782 18.9702 57.0229V46.5675H11.3981C9.73923 46.5675 8.91093 44.3079 10.0889 42.9918L21.2901 30.4845C22.0626 29.6206 23.3103 29.6206 24.0829 30.4845L35.2841 42.9918C36.4632 44.3079 35.6361 46.5675 33.9771 46.5675ZM44.1793 14.2852L32.8061 1.47735C32.2834 0.889231 31.5747 0.5625 30.8312 0.5625H30.1226V17.2911H44.9925V16.4939C44.9925 15.6705 44.7021 14.8733 44.1793 14.2852Z"
        fill="#B5B5B5"
      />
    </svg>
  );
}

export const UploadKamera = ({ style, onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      viewBox="0 0 151 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M150.261 33.9333V119.855C150.261 127.761 143.956 134.175 136.184 134.175H14.1826C6.41084 134.175 0.105469 127.761 0.105469 119.855V33.9333C0.105469 26.0274 6.41084 19.6131 14.1826 19.6131H39.9906L43.5979 9.79783C45.6508 4.21892 50.9004 0.519531 56.7659 0.519531H93.5717C99.4371 0.519531 104.687 4.21892 106.74 9.79783L110.376 19.6131H136.184C143.956 19.6131 150.261 26.0274 150.261 33.9333ZM110.376 76.894C110.376 57.144 94.5981 41.0935 75.1834 41.0935C55.7687 41.0935 39.9906 57.144 39.9906 76.894C39.9906 96.6439 55.7687 112.694 75.1834 112.694C94.5981 112.694 110.376 96.6439 110.376 76.894ZM100.991 76.894C100.991 91.3633 89.4072 103.148 75.1834 103.148C60.9597 103.148 49.3754 91.3633 49.3754 76.894C49.3754 62.4246 60.9597 50.6403 75.1834 50.6403C89.4072 50.6403 100.991 62.4246 100.991 76.894Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};
