export function getFormattedAccountValue(value, bahasa) {
  if (value.accountName) {
    if (value.availBalance === undefined) {
      return `${value.prodName} / ${value.accountNo} - ${
        value.accountName 
      }`
    } else {
      return `${value.prodName} / ${value.accountNo} - ${
        value.accountName 
      } / ${new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: value.acctCur || "IDR",
      }).format(+value.availBalance)}`;
    }
  } else if (value[`label${bahasa}`]) {
    return value[`label${bahasa}`];
  } else if (value.label && value.label[`label${bahasa}`]) {
    return value.label[`label${bahasa}`];
  } else if (value[`placeholder${bahasa}`]) {
    return value[`placeholder${bahasa}`];
  } else if (value.placeholder && value.placeholder[`placeholder${bahasa}`]) {
    return value.placeholder[`placeholder${bahasa}`];
  } else if (value?.prefix) {
    return `${value.prefix} - ${value.name}`;
  } else {
    return value;
  }
}

function checkDateFormat(value) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateRegex.test(value)) {
    const [year, month, day] = value.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  return value;
}

export function mirroringKey(formState, isMultiForm, language, mirrorKey) {
  
  const wrapper = mirrorKey && mirrorKey?.split(".");
  if (isMultiForm) {
    const modFormState = Array.isArray(formState) ? formState : [formState]
    const res = modFormState.map((val) => ({
      ...Object.fromEntries(
        Object.entries(val).map(([key, value]) => {
          if (
            typeof value === "object" &&
            value &&
            "label" in value &&
            "value" in value
          ) {
            
            return [key, value.label];
          } else {
            return [
              key,
              typeof value === "object" && value
                ? getFormattedAccountValue(value, language)
                : checkDateFormat(value),
            ];
          }
        })
      ),
    }));
    if (mirrorKey) {
      return wrapper.reduceRight((acc, key) => ({ [key]: acc }), res);
    }
    return res;
  } else if (formState?.length > 0) {
    const res = {
      ...Object.fromEntries(
        Object.entries(formState[0]).map(([key, value]) => [
          key,
          typeof value === "object" && value
            ? getFormattedAccountValue(value, language)
            : checkDateFormat(value),
        ])
      ),
    };
    if (mirrorKey) {
      return wrapper.reduceRight((acc, key) => ({ [key]: acc }), res);
    }
    return res;
  } else {
    const res = {
      ...Object.fromEntries(
        Object.entries(formState).map(([key, value]) => [
          key,
          typeof value === "object" && value
            ? getFormattedAccountValue(value, language)
            : checkDateFormat(value),
        ])
      ),
    };
    if (mirrorKey) {
      return wrapper.reduceRight((acc, key) => ({ [key]: acc }), res);
    }
    return res;
  }
}
