import { Client } from "@stomp/stompjs";

export const client = new Client({
    brokerURL: "wss://app.dips361.co.id/stomp/ws",
      connectHeaders: {
        login: "dips361",
        passcode: "dips2022",
      },
      onConnect: (frame) => {
        if (frame.command === "CONNECTED") {
            
        }
      },
      onDisconnect: (frame) => {
        
      },
      reconnectDelay: 5000,
      onStompError: (err) => {
        
      },
      onWebSocketError: (err) => {
        
      },
      // debug: (str) => {
      //   
      // }
})

export function publish(dest, body) {
    
    client.publish(dest, body);
}

export function listen(dest) {
    
    client.subscribe(dest, (msg) => {
        
        return msg
    })
}