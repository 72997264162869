import moment from "moment";

export default function formatString(label) {
    return label.toLowerCase().replace(/[ ./-]/g, "")
}

export function getLabelFromKey(form) {
  let defaultObj = null;
  form.forEach((val, i) => {
    if (!["Header Text", "Label", "Paragraph"]?.includes(val?.name)) {
      val.components.forEach((component) => {
        if (component.name === "Datepicker") {
          defaultObj = {
            ...defaultObj,
            [component.label.labelIdn.toLowerCase().replace(/[ ./-]/g, "")]:
              moment().format("YYYY-MM-DD"),
          };
        } else if (component.label.labelIdn) {
          defaultObj = {
            ...defaultObj,
            [component.label.labelIdn.toLowerCase().replace(/[ ./-]/g, "")]:
              null,
          };
        }
      });
    }
  });
  return defaultObj;
}


  export function mergeObjects(obj1, obj2) {
    const mergedObj = {
      from: obj1.from,
      noForm: obj1.noForm,
      retrievalReferenceNumber: obj1.retrievalReferenceNumber,
      systemTraceAuditNumber: obj1.systemTraceAuditNumber,
    };

    for (const key in obj1) {
      if (obj2.hasOwnProperty(key)) {
        mergedObj[key] = obj1[key]; // Keep values from obj1 for existing keys
      }
    }

    for (const key in obj2) {
      if (!mergedObj.hasOwnProperty(key)) {
        mergedObj[key] = null;
      }
    }

    return mergedObj;
  }